import {
  FaCode,
  FaUnity,
  FaCubes,
  FaDatabase,
  FaBriefcase,
  FaJava,
  FaChevronUp,
  FaChevronDown,
  FaVideo,
  FaBell,
  FaDownload,
  FaMedal,
  FaExpand,
  FaChevronRight,
  FaArrowRight,
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaSignal,
  FaCompress,
  FaSearch,
  FaDev,
  FaLinkedin,
  FaQuestionCircle,
} from "react-icons/fa";
import {
  FaUpRightFromSquare,
  FaComputer,
  FaNetworkWired,
  FaClock,
  FaBookOpenReader,
  FaLink,
} from "react-icons/fa6";
import { CiCalendarDate } from "react-icons/ci";
import { PiCertificate } from "react-icons/pi";
import {
  RiGameFill,
  RiRobot2Fill,
  RiCustomerServiceFill,
} from "react-icons/ri";
import {
  BsFillBadgeVrFill,
  BsCheck2Circle,
  BsPersonVcard,
  BsInfinity,
  BsFacebook,
  BsLinkedin,
  BsPlayCircle,
  BsArrowUpRight,
  BsPeopleFill,
  BsBook,
  BsClockFill,
  BsCalendar2Day,
  BsSpeedometer2,
  BsPersonVideo2,
  BsSuitcaseLgFill,
  BsPlayBtnFill,
  BsCameraVideoFill,
  BsBookFill,
  BsCodeSlash,
  BsClockHistory,
  BsDiscord,
} from "react-icons/bs";
import {
  SiUnrealengine,
  SiApplearcade,
  SiCodeproject,
  SiCplusplus,
  SiCoursera,
  SiPython,
  SiHtml5,
  SiJavascript,
  SiCsharp,
} from "react-icons/si";
import {
  GrTechnology,
  GrCertificate,
  GrNotes,
  GrGamepad,
} from "react-icons/gr";
import { GiFamilyTree, GiGamepad, GiTeacher } from "react-icons/gi";
import {
  MdOutlineClose,
  MdOutlineWork,
  MdDesignServices,
  MdOutlineAssignmentTurnedIn,
  MdOutlineQuestionMark,
  MdOutlineQuestionAnswer,
  MdEditDocument,
  MdLockOutline,
  MdRateReview,
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt2Bar,
  MdOutlineShowChart,
  MdPhonelink,
  MdOutlineAssignment,
} from "react-icons/md";
import {
  AiOutlineClockCircle,
  AiFillSafetyCertificate,
  AiOutlineInstagram,
  AiFillYoutube,
  AiOutlineDiscord,
  AiOutlineCloudDownload,
  AiOutlineFileSearch,
} from "react-icons/ai";
import { GoCode, GoDotFill, GoDeviceMobile, GoQuestion } from "react-icons/go";
import { DiScala } from "react-icons/di";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { RxDotFilled, RxUpdate } from "react-icons/rx";
import { IoGameController, IoTime, IoCopyOutline } from "react-icons/io5";
import { LiaDiscord } from "react-icons/lia";
import { FiCheckCircle } from "react-icons/fi";

export const iconsList = {
  FaCode,
  FaDev,
  FaNetworkWired,
  IoCopyOutline,
  CiCalendarDate,
  FaSearch,
  FaComputer,
  FaLinkedin,
  FaQuestionCircle,
  FaCompress,
  BsCodeSlash,
  FaClock,
  GrNotes,
  FaBell,
  FaUpRightFromSquare,
  RiCustomerServiceFill,
  FaMedal,
  FaExpand,
  MdOutlineWork,
  RiGameFill,
  FaLink,
  RiRobot2Fill,
  BsFillBadgeVrFill,
  FaUnity,
  IoTime,
  SiUnrealengine,
  GrTechnology,
  GrGamepad,
  GrCertificate,
  SiApplearcade,
  GiGamepad,
  MdDesignServices,
  FaCubes,
  FaDatabase,
  BsCheck2Circle,
  BsCameraVideoFill,
  BsBookFill,
  MdOutlineClose,
  MdOutlineAssignmentTurnedIn,
  AiOutlineClockCircle,
  FaBriefcase,
  MdOutlineQuestionMark,
  GoCode,
  SiCodeproject,
  HiOutlineArrowNarrowRight,
  GiTeacher,
  BsPersonVcard,
  MdOutlineQuestionAnswer,
  SiCplusplus,
  SiCoursera,
  SiPython,
  SiHtml5,
  SiJavascript,
  SiCsharp,
  FaJava,
  DiScala,
  GoDotFill,
  FaChevronUp,
  FaChevronDown,
  FaVideo,
  FaDownload,
  BsInfinity,
  MdEditDocument,
  AiFillSafetyCertificate,
  GoDeviceMobile,
  AiOutlineInstagram,
  BsFacebook,
  BsLinkedin,
  BsPlayCircle,
  BsArrowUpRight,
  MdLockOutline,
  GoQuestion,
  BsPeopleFill,
  RxDotFilled,
  BsBook,
  BsClockFill,
  IoGameController,
  BsCalendar2Day,
  BsSpeedometer2,
  BsPersonVideo2,
  MdRateReview,
  BsSuitcaseLgFill,
  FaChevronRight,
  FaArrowRight,
  FaChevronCircleLeft,
  FaChevronCircleRight,
  PiCertificate,
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt2Bar,
  FaSignal,
  MdOutlineShowChart,
  AiFillYoutube,
  BsPlayBtnFill,
  LiaDiscord,
  FaBookOpenReader,
  MdPhonelink,
  BsClockHistory,
  FiCheckCircle,
  AiOutlineDiscord,
  BsDiscord,
  MdOutlineAssignment,
  AiOutlineCloudDownload,
  AiOutlineFileSearch,
  GiFamilyTree,
  RxUpdate,
};
