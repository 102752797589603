import { toast } from "react-toastify";
import { themeStylesMap } from "./CodeViewer.model";

class CodeViewerController {
  constructor(viewMode = "LIGHT") {
    this.viewMode = viewMode || "LIGHT";

    this.actionsMap = {
      COPY: this.copyCode,
    };
  }

  copyCode(code) {
    if (!code || typeof window === "undefined" || !navigator?.clipboard) {
      return;
    }
    navigator.clipboard.writeText(code).then(() => {
      toast.success("Code copied successfully!");
    });
  }

  get themeStyles() {
    return themeStylesMap[this.viewMode];
  }
}

export default CodeViewerController;
