export const codeTagStyles = {
  style: {
    textShadow: "0 0 black",
  },
};

export const commonStyles = {
  margin: 0,
  borderRadius: "0 0 8px 8px",
  fontSize: "14px",
  padding: "10px",
};

export const themeStylesMap = {
  DARK: {
    ...commonStyles,
    backgroundColor: "#1e1e1e",
    color: "#ffffff",
  },
  LIGHT: {
    ...commonStyles,
    color: "#000000",
  },
};
