import React from "react";
import dynamic from "next/dynamic";
import styles from "./CodeViewer.module.css";
import CodeViewerController from "./CodeViewer.controller";
import { codeTagStyles } from "./CodeViewer.model";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";

const SyntaxHighlighter = dynamic(
  () => import("react-syntax-highlighter").then((mod) => mod.Prism),
  { ssr: false }
);

const CodeViewer = ({
  fileName = "",
  ViewMode: viewMode = "LIGHT",
  codeSnippet = "",
  language = "javascript",
  showLineNumbers = true,
}) => {
  const controller = new CodeViewerController(viewMode);

  return (
    codeSnippet && (
      <div>
        <div className={styles.topbar}>
          <p className={styles.filename}>{fileName}</p>
          <div
            className={styles.iconText}
            onClick={() => {
              controller.copyCode(codeSnippet);
            }}
          >
            <p className={styles.actionText}>Copy</p>
            <DynamicIcon icon="IoCopyOutline" size={14} />
          </div>
        </div>
        <SyntaxHighlighter
          language={language}
          showLineNumbers={showLineNumbers}
          customStyle={controller.themeStyles}
          codeTagProps={{ ...codeTagStyles }}
        >
          {codeSnippet}
        </SyntaxHighlighter>
      </div>
    )
  );
};

export default CodeViewer;
