import React from "react";
import styles from "./IconTextButton.module.css";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import DynamicIcon from "../../DynamicIcons/DynamicIcons.controller";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const IconTextButton = ({
  icon = "",
  text = "",
  link = "",
  width = "100%",
  section_id = "",
  sticky = null,
  showOn,
}) => {
  if (!link || !text) return null;

  const style = {
    width: "100%",
    ...(Number.isInteger(sticky) && sticky !== null
      ? { position: "sticky", top: `${sticky}px`, zIndex: 1 }
      : {}),
  };

  return (
    <UseComponentVisibility conditions={showOn}>
      <div style={style}>
        <ConditionLinkView link={link} section_id={section_id}>
          <div className={styles.cta}>
            {icon && <DynamicIcon icon={icon} />}
            {text}
          </div>
        </ConditionLinkView>
      </div>
    </UseComponentVisibility>
  );
};

export default IconTextButton;
